import './kiso_themes/kernel';
import './kiso_themes/fastclick';
import './kiso_themes/slimscroll';
import './kiso_themes/metis_menu';
import './kiso_themes/popper';
import './kiso_themes/bootstrap';
import './kiso_themes/sidenav';
import './kiso_themes/header';
import './kiso_themes/forms';
import './kiso_themes/bootstrap_switch';
import './kiso_themes/sheets';
import './kiso_themes/gmaps';
import './kiso_themes/jqvectormaps';
import './kiso_themes/jasny';
import './kiso_themes/flot';
import './kiso_themes/chartjs';
import './kiso_themes/sparklines';
import './kiso_themes/easypiechart';
import './kiso_themes/prettify';
import './kiso_themes/countTo';
import './kiso_themes/prism';
import './kiso_themes/clipboard';
import './kiso_themes/countdown';
import './kiso_themes/parallax';

import './kiso_themes/demo/data_generator';
import './kiso_themes/demo/chartjs';
import './kiso_themes/demo/dashboard';
import './kiso_themes/demo/easypiechart';
import './kiso_themes/demo/flot';
import './kiso_themes/demo/gmaps';
import './kiso_themes/demo/jqvectormap';
import './kiso_themes/demo/sparklines';

import './kiso_themes/inspect_mode/inspect_mode';

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
//Popper.Defaults.modifiers.computeStyle.enabled = false;

