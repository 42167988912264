import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    
  }

  toggle(e) {
    e.preventDefault()
    const modelKey = this.data.get('modelKey');
    const state = this.data.get('state');
    const endpoint = this.data.get('endpoint');

    let data = new FormData();
    data.append(`${modelKey}[active]`, !(state == 'true' ? true : false))
    Rails.ajax({
      url: endpoint,
      type: 'PATCH',
      data: data,
      dataType: 'json',
      success: function(data) {
        const domId = `#${modelKey}_${data.id}`
        const node = document.querySelector(domId)
        node.outerHTML = data.html
      }
    })
  }
}
