import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function createEditor(elements) {
  for (let i = 0; i < elements.length; ++i) {
    ClassicEditor
      .create( elements[i], {
        removePlugins: [ 'EasyImage', 'ImageUpload' ],
        mediaEmbed: {
          previewsInData: true
        }
      } )
      .then( editor => {
          //console.log( editor );
      } )
      .catch( error => {
          //console.error( error );
      } );
  }
}
document.addEventListener('turbolinks:load', function() {
  //console.log(ClassicEditor.builtinPlugins.map( plugin => plugin.pluginName ));
  const elements = document.querySelectorAll('.wysiwyg-editor')
  createEditor(elements);
})

document.addEventListener('nested-item-added', function(e) {
  const elements = e.target.querySelectorAll('.new-record .wysiwyg-editor');
  createEditor(elements);
});