import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.end.bind(this),
      animation: 150,
      handle: '.handle',
      ghostClass: 'bg-gray-300',
      filter: '.non-sortable'
    })
  }

  end(event) {
    let id = event.item.dataset.id;
    let data = new FormData();
    let positionMakeUp = this.data.get('positionMakeUp') || 1;
    let newPosition = event.newIndex + parseInt(positionMakeUp, 10);
    data.append("position", newPosition)
    Rails.ajax({
      url: this.data.get('url').replace(":id", id),
      type: 'PATCH',
      data: data, 
      success() {
        console.log("Sorted:", id, "to position:", newPosition )
      }
    })
  }
}
