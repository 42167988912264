// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// ***** START: ADDED BY KisoThemes *****
import $ from 'jquery'
window.$ = window.jQuery = $

// ***** END: ADDED BY KisoThemes *****

import { start as startUjs } from "@rails/ujs"
import { start as startTurbolinks } from "turbolinks"
import { start as startActiveStorage } from "@rails/activestorage"

startUjs();
startActiveStorage();
startTurbolinks();

import "channels"
// ***** START: ADDED BY KisoThemes *****
import "bootstrap"
import "metismenu/dist/metisMenu"
// require("jquery-slimscroll")
// require('bootstrap-switch')
import "chartkick"
import "chart.js"
// require('jquery-countdown')
// require('jquery-countto')
// require('cd-easypiechart')
import "fastclick"
import "object-fit-images"
// require('flot/source/jquery.canvaswrapper');
// require('flot/source/jquery.flot');
// require('gmaps.core')
// require('jasny-bootstrap')
// require('jvectormap')
// require('jquery-parallax.js')
// require('code-prettify')
// require('prismjs')
// require('sparklines')


// ***** END: ADDED BY KisoThemes *****

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
//import "../addons/redactor";
import "../addons/ckeditor";
import "../addons/choices.css";
import "../addons/choices";
import "dropzone/dist/min/dropzone.min.css";
import "dropzone/dist/min/basic.min.css";
// require("trix")
// require("@rails/actiontext")

require("kiso_themes")

$(function(){
  var hash = window.location.hash;
  hash && $('ul.nav a[href="' + hash + '"]').tab('show');

  $('.nav-tabs a').click(function (e) {
    $(this).tab('show');
    var scrollmem = $('body').scrollTop();
    window.location.hash = this.hash;
    $('html,body').scrollTop(scrollmem);
  });
});