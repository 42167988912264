import Choices from 'choices.js/public/assets/scripts/choices';

function setup(elements) {
  for (let i = 0; i < elements.length; ++i) {
    new Choices(elements[i], {
      addItems: true
    })
  }
}
document.addEventListener('turbolinks:load', function() {
  const elements = document.querySelectorAll('.choicesjs')
  setup(elements);
})

document.addEventListener('nested-item-added', function(e) {
  const elements = e.target.querySelectorAll('.new-record .choicesjs');
  setup(elements);
});